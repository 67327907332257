.videolistlayout{
    display:flex;
    flex-direction:column;
}
.videolist{
    display:flex;
    justify-content: space-between;
    flex-wrap: wrap;
    height: 200px;
    overflow:auto;
}
.videolisth{
    display:flex;
    /* justify-content: space-evenly; */
    flex-wrap: wrap;
    height: 350px;
    overflow:auto;
}
.videoshow{
    width:220px;
    height: 90px;
    margin: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border: 1px solid #ccc; */
}
.videoshowv{
    flex:5;
    margin-top: 15px;
    width:90px;
    height: 100%;
}
.videoshowi{
    flex:1;
    
    font-size: 22px;
    color: #F08080;
    margin-left: 10px;
}
.videoshowt{
    text-align: left;
    margin: 10px;
    width:220px;
    text-overflow:-o-ellipsis-lastline; 
    overflow:hidden; 
    text-overflow: ellipsis; 
    display: -webkit-box; 
    -webkit-line-clamp: 2; 
    -webkit-box-orient: vertical; 
}
.videostyle{
    display: flex;
    align-items: center;
    /* // flex-direction: column; */
    /* // display: inline; */
    box-shadow: 0px 3px 6px #00000029;
    /* // margin: 10px; */
    /* margin-bottom: 20px; */
    width: 260px;
    height: 120px;
    border: 1px solid #f7f6f9;
    border-radius: 8px;
    flex-basis:auto;
    
    
    
}
.leftvideo{
    cursor:pointer;
    width: 120px;
    height: 120px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    margin-top: 5px;
    /* border: 1px solid red; */
    /* border-radius: 8px; */

}
.righttext{
    width: 100%;
    display: inline-block;
    text-align: left;
    /* // padding: 10px; */
    padding: 5px 10px;
    text-overflow:-o-ellipsis-lastline; 
    overflow:hidden; 
    text-overflow: ellipsis; 
    display: -webkit-box; 
    -webkit-line-clamp: 2; 
    -webkit-box-orient: vertical; 
}
.videosbox{
    display:flex;
    /* align-items: center; */
    height: 120px;
    margin: 5px;
    /* border: 1px solid red; */
}
.repetition{
  color:red;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.repetition .title{
  color:blue;
  font-size: 18px;
}

.repetition .time{
  border: 10px solid red;
  width:80px;
  height:80px;
  display: flex;
  align-items: center;
  justify-content:space-around;
  font-size:45px;
  font-weight: bold;
}


.timer{
  color:red;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.timer .title{
  color:blue;
  font-size: 18px;
}

.timer .time{
  border: 10px solid red;
  border-radius: 40px;
  width:80px;
  height:80px;
  display: flex;
  align-items: center;
  justify-content:space-around;
  font-size:45px;
  font-weight: bold;
}
.myexcerciselist{
    display: flex;
    align-items: center;
}
.excerciseleft{
    
    font-size:30px;
    margin: 5px;
}
.videoshow{
    /* flex: 1; */
    display: flex;
    align-items: center;
    width: 100%;
}
.videodetail{
    flex: 1;
    margin: 10px;
    height: 100px;
    border: 1px solid red;
}

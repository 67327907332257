.formContainer{
    position: fixed;
    background-color: rgba(0,0,0,0.4);
    width: 500px;
    height: 250px;
    top:50%;
    left: 50%;
    transform: translate(-50%,-50%);
    padding: 20px;
    border-radius: 15px;
}
.loginTitle{
    color:white;
    font-size: 40px;
    text-align: center;
    padding-bottom: 20px;
}
.login-form-button-list{
    /* background-color: red; */
    text-align: center;
    /* display: flex;
    flex-direction: row; 
    align-items: center; */
    /* width: 100%; */
    /* 
    */
    /*  */
    
}
/* Button {
    width: 100px;
    margin: 10px;
    
    background-color: #D8BFD8;
    height: 30px;
    border: 0;
    border-radius: 15px;
    color: #8A2BE2;
    font-size: 16px;
    font-weight: bold;
    
} */
/* button:hover {
    width: 100px;
    margin: 10px;
    background-color: #7D26CD;
    height: 30px;
    border: 0;
    border-radius: 15px;
    color: white;
    font-size: 16px;
    font-weight: bold;
    
} */
.sessionLayout{
    display:flex;
    /* height:"200px"; */
    /* overflow: auto; */
    /* border:1px solid red; */
}
.slLeft{
    /* height: "100%"; */
    flex:3;
    border-right:1px solid #ccc;
    padding-right: 10px;
    /* padding-bottom: 15px; */
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.slRight{
    flex:3;
    padding-left: 10px;
}
/* *{margin: 0;padding: 0;} */
/* .editor{

    border:"1px solid red";
    height:'200px'
} */
/* html,body{
    height: 100%;
} */
